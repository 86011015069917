<template>
  <div class="draggable-content">
    <draggable
      v-model="items"
      :draggable="'.itemDraggable' + parentID"
      style="width:100%;margin: 0px;"
      :animation="200"
      :group="parentID"
      ghostClass="ghost"
      @start="handleSortStart(parentID)"
      @end="handleSortItems(items)"
      :options="{disabled : !dtouchSimulationAllowSort}"
    >
      <div 
        style="cursor: pointer;"
        v-for="item in items" 
        :key="item.id + JSON.stringify(item.name)"
        v-bind:class="'itemDraggable' + parentID"
      >
        <item-header
          :isEditing="isEditing "
          :editedID="editedID"
          :item="item"
          :parentID="parentID"
          :color="colors[level]"
          :onExpand="handleExpand"
          :onAddItem="handleAddItem"
          :onEditItem="onEditItem"
          :onCloneItem="onCloneItem"
          :refreshContent="refreshContent"
          :isExpand="expandID === item.id"
          :hideByType="hideByType"
          :hideVisibility="hideVisibility"
          :cloneMenu="cloneMenu"
          :cloneContent="cloneContent"
          :locale="locale"
          :localeSimulation="forceLocale"
          :onChangeItemsToDelete="onChangeItemsToDelete"
        />
        <v-progress-linear
          color="primary"
          :active="loading"
          :indeterminate="loading"
        />
        <v-row
            align="center"
          v-if="expandID === item.id || expandID === 'hide'"
          v-show="expandID !== 'hide'"
          :class="item.isMenuWithSubmenus ? 'subitem-header-action' : 'item-header-action'"
            style="border-radius: 5px; border: 1px solid #CCC;"
        >
          <v-col md="auto"
            v-if="item.isMenu || item.isMenuWithSubmenus"
            @click="handleAddItem(item)"
            >
            <v-icon dark>mdi-plus-box</v-icon>
          </v-col>
          <v-col>
            <draggable-menu-list
              v-if="expandID === item.id || expandID === 'hide'"
              v-show="expandID !== 'hide'"
              :parentID="item.id"
              :isMenuWithSubmenus="item.isMenuWithSubmenus"
              :level="level+1"
              :onEditItem="onEditItem"
              :onCloneItem="onCloneItem"
              :onSort="onSort"
              :contentTypes="contentTypes"
              :fullRefresh="fullRefresh"
              :addContent="addContent"
              :moveMenu="moveMenu"
              :refreshContent="refreshContent"
              :removeContent="removeContent"
              :hideByType="hideByType"
              :isEditing="editedID !== null"
              :editedID="editedID"
              :cloneMenu="cloneMenu"
              :cloneContent="cloneContent"
              :contentTypeFilter="contentTypeFilter"
              :onChangeItemsToDelete="onChangeItemsToDelete"
            />
          </v-col>
        </v-row>
        <!--draggable-menu-list
          v-if="expandID === item.id || expandID === 'hide'"
          v-show="expandID !== 'hide'"
          :parentID="item.id"
          :isMenuWithSubmenus="item.isMenuWithSubmenus"
          :level="level+1"
          :onEditItem="onEditItem"
          :contentTypes="contentTypes"
          :refreshContent="refreshContent"
          :hideByType="hideByType"
          :isEditing="editedID !== null"
          :editedID="editedID"
        /-->
      </div>
    </draggable>
    <show-more
      :count="count"
      :length="items.length"
      :onShowMore="handleShowMore"
      :right="!isMenuWithSubmenus"
      :level="level"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import utils from '@/services/utils'
import api from '@/services/api'
import DraggableMenuList from './DraggableMenuList'
import ItemHeader from './ItemHeader'
import ShowMore from './ShowMore'
export default {
  name: 'DraggableMenuList',
  components: {
    draggable,
    DraggableMenuList,
    ItemHeader,
    ShowMore,
  },
  props: {
    parentID: {
      type: String,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    editedID: {
      type: String,
      default: null,
    },
    isMenuWithSubmenus: {
      type: Boolean,
      default: true,
    },
    level: {
      type: Number,
      default: 0,
    },
    contentTypes: {
      type: Array,
      default: null,
    },
    onEditItem: {
      type: Function,
      default: null,
    },
    onCloneItem: {
      type: Function,
      default: null,
    },
    onChangeItemsToDelete: {
      type: Function,
      default: null,
    },
    fullRefresh: {
      type: Object,
      default: null,
    },
    addContent: {
      type: Object,
      default: null,
    },
    moveMenu: {
      type: Object,
      default: null,
    },
    refreshContent: {
      type: Object,
      default: null,
    },
    removeContent: {
      type: Object,
      default: null,
    },
    hideByType: {
      type: Boolean,
      default: false,
    },
    hideVisibility: {
      type: Boolean,
      default: false,
    },
    cloneMenu: {
      type: Boolean,
      defatult: false,
    },
    cloneContent: {
      type: Boolean,
      default: false,
    },
    onSort: {
      type: Function,
      required: true,
    },
    contentTypeFilter: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    //workspaceID: null,
    loading: false,
    //colors: ['#FFFFFF', '#FAFAFA', '#F5F5F5', '#EEEEEE', '#E0E0E0', '#BDBDBD'],
    colors: ['#FFFFFF', '#F5F5F5', '#E0E0E0', '#BDBDBD'],
    items: [],
    startIndex: 0,
    itemsPerPage: 500,
    count: null,
    expandID: null,

    isSearchResult: false,
    menus: null,
    search: '',
    activeDraggable: null,
    //:disabled="!((activeDraggable === parentID || activeDraggable === '-1') && !isSearchResult)"
    forceLocale: null,
  }),
  computed: {
    ...mapState('app', ['locale', 'dtouchSimulationLocale','dtouchDraggableMenuUpdated', 'dtouchSimulationAllowSort']),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    moveMenu () {
      if (this.isMenuWithSubmenus) {
        this.loading = []
        this.items = []
        this.handleGetMenus()
      }
    },
    'dtouchDraggableMenuUpdated.LMD' () {
      if (this.dtouchDraggableMenuUpdated.MenuID === this.parentID) {
        this.items = []
        this.handleGetData()
      }
    },
    dtouchSimulationLocale (v) {
      this.forceLocale = v ? v : this.locale
    },
    /*
    dtouchWorkspace (v, newValue) {
      console.log('jhm', v, newValue)
      this.items = []
      this.workspaceID = v
      this.handleGetData()
    },
    */
    fullRefresh (v) {
      if (this.parentID === v.id) {
        this.items = []
        this.handleGetData()
      }
    },
    removeContent (v) {
      const aux = this.items.length
      this.items = this.items.filter(x => x.id !== v.id)
      this.count -= (aux - this.items.length) // remove counter total if element exist
    },
    addContent (v) {
      if (this.parentID === v.item.ParentID || this.parentID === v.item.MenuID) {
        this.items.push(v.item.isMenu ? this.prepareMenu(v.item) : this.prepareContent(v.item))
        this.count++
      }
    },
    contentTypeFilter () {
      this.items = []
      this.handleGetData()
    },
    /*refreshContent (v) {
      if (v) {
        const aux = this.items.filter(x => x.id === v.id)
        if (aux.length > 0)
          if (aux[0].isMenu) this.handleGetMenuItem(v.id)
          else this.handleGetContentItem(v.id)
      }
    },*/
  },
  mounted () {
    this.forceLocale = this.dtouchSimulationLocale ? this.dtouchSimulationLocale : this.locale
    this.items = []
    //this.workspaceID = utils.getWorkspace('dtouch')
    this.handleGetData()
  },
  methods: {
    getContentTypeData (v) {
      const aux = this.contentTypes.filter(x => x.id === v).shift()
      return {
        id: aux ? aux.id : null,
        icon: aux ? aux.icon : 'mdi-format-list-bulleted-type',
        name: aux ? aux.name : null
      }
    },
    handleGetData () {
      this.loading = true
      if (this.isMenuWithSubmenus) this.handleGetMenus()
      else this.handleGetContents()
    },
    prepareMenu (item) {
      return {
        id: item.ID,
        name: utils.getTranslation(item.Name, this.forceLocale),
        isMenuWithSubmenus: item.Type === 1,
        isMenu: true,
        menuBGColor: item.MenuBGColor,
        menuTextColor: item.MenuTextColor,
        contentType: {
          icon: 'mdi-menu',
          name: 'menu',
        },
        hideStatus: {
          hideMobile: item.HideMobile === 1 ? true : false,
          hideDesktop: item.HideDesktop === 1 ? true : false,
          hideTotem: item.HideTotem === 1 ? true : false,
        },
        private: item.Private === 1,
        isTemp: item.IsTemp,
      }
    },
    prepareContent (item) {
      const contentType = this.getContentTypeData(item.Type)
      return {
        id: item.ID,
        name: item.Alias,//utils.getTranslation(JSON.parse(item.Name), this.locale),
        isMenuWithSubmenus: false,
        isMenu: false,
        contentType,
        key: new Date().getTime(),
        hideStatus: {
          hideMobile: item.HideMobile === 1 ? true : false,
          hideDesktop: item.HideDesktop === 1 ? true : false,
          hideTotem: item.HideTotem === 1 ? true : false,
        },
        isTemp: item.IsTemp,
      }
    },
    handleGetMenus () {
      api.getAll ('dtouch', `v1/private/workspaces/${this.workspaceID}/menus/${this.parentID}/`, this.startIndex, this.itemsPerPage, this.search ? this.search : '')
        .then(response => {
          this.count = response.count
          this.items.push(...response.data.map(item => {
            return this.prepareMenu(item)
          }))
          this.loading = false
        })
    },
    handleGetContents () {
      api.getAll ('dtouch', `v1/private/menus/${this.parentID}/contents/`, this.startIndex, this.itemsPerPage, this.search ? this.search : '')
        .then(response => {
          this.count = response.count
          if (this.contentTypeFilter) response.data = response.data.filter(x => this.contentTypeFilter.indexOf(x.Type) >= 0)
          this.items.push(...response.data.map(item => {
            return this.prepareContent(item)
          }))
          this.loading = false
        })
    },
    handleShowMore () {
      this.startIndex += this.itemsPerPage
      this.handleGetData()
    },
    handleSortStart (v) {
      this.activeDragable = v // disable sorting until the action is finished
    },
    handleSortItems (v) {
      this.activeDragable = '-1' // enable sorting once the action is finished
      api.put ('dtouch', `v1/private/${this.isMenuWithSubmenus ? 'menus' : 'contents'}/sort/`, v.map(x => x.id))
        .then(() => {
          this.onSort(this.parentID)
        })
    },
    handleExpand (id) {
      this.expandID = this.expandID === id ? 'hide' : id
    },
    handleAddItem (v) {
      if (v.isMenuWithSubmenus) this.onEditItem(true, 'new', v.id)
      else this.onEditItem(false, 'new', v.id)
    }
  },
}
</script>
<style scoped>
.item-header-action {
  background: repeating-linear-gradient(45deg, #34495e, #34495e 20px, #2c3e50 20px, #2c3e50 40px);
}

.subitem-header-action {
  background: repeating-linear-gradient(45deg, #5d6d7e, #5d6d7e  20px, #566573  20px, #566573  40px);
}
</style>

